<template>
  <div id="order-view-services">
    <b-overlay :show="quoteLoading">
      <b-card no-body>
        <b-card-body>
          <div class="d-block d-sm-flex justify-content-between align-items-end">
            <h4>
              {{ $t('order.service_tracking') }}
            </h4>
            <b-button
              v-if="servicesEditionStatus"
              id="order-view-service-update-all-services-to-delivered"
              v-ripple.400
              :block="$store.getters[`app/smAndDown`]"
              variant="outline-primary"
              @click.prevent="updateAllServicesToDelivered()"
            >
              <span class="text-nowrap">
                {{ $t('order.update_all_services_to_delivered') }}
              </span>
            </b-button>
          </div>
          <div v-if="isQuoteOwner" class="d-flex justify-content-start align-items-end mt-1 note-min-width">
            <span>{{ $t('common.note') }} {{ $t('order.service_tracking_note') }}</span>
          </div>
        </b-card-body>
        <validation-observer ref="order-view-services-observer">
          <b-table
            ref="order-view-services"
            :class="['position-relative', { 'table-dropdown-action': servicesEditionDates || servicesEditionStatus }]"
            thead-class="text-nowrap"
            :stacked="$store.getters[`app/mdAndDown`]"
            :items="quote.lines.filter(line => this.isValidLineForConditions(line))"
            :fields="linesDocumentFields"
            :empty-text="$t('common.no_record_found')"
          >
            <!-- LOADING -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle" />
              </div>
            </template>
            <!-- CELL -->
            <!-- date -->
            <template #cell(date)="data">
              <!-- suppression suite à la demande MFC2-534 : && data.item.lineClass !== 'HangarQuoteLine' -->
              <div>
                <b-form-group v-if="servicesEditionDates && selectedOrganization.id === quote.organization.id" class="mt-md-1 input-group">
                  <flat-pickr
                    :id="`order-view-service-${data.item.id}-date`"
                    v-model="data.item.date"
                    class="form-control"
                    :config="{
                      altInput: true,
                      altFormat: 'F j, Y H:i',
                      dateFormat: 'Y-m-dTH:i:S+00:00', // Date en UTC
                      enableTime: true,
                      time_24hr: true,
                      defaultDate: data.item.date,
                      locale: $i18n.locale,
                      // Permet d'avoir la date affichée en UTC
                      onReady: (selectedDates, dateStr, instance) => {
                        instance.setDate(new Date(selectedDates[0].toISOString()), false);
                      },
                    }"
                    @on-close="updateLineDate(data.item)"
                  />
                </b-form-group>
                <span v-else class="text-nowrap">
                  <span v-if="data.item.date">
                    {{ $moment.utc(data.item.date).format('ddd, MMM Do YYYY HH:mm') }}
                  </span>
                </span>
              </div>
              <div v-if="data.item.lineClass === 'HangarQuoteLine'">
                <b-form-group v-if="servicesEditionDates && selectedOrganization.id === quote.organization.id" class="mt-md-1 input-group">
                  <flat-pickr
                    :id="`order-view-service-${data.item.id}-end-at`"
                    v-model="data.item.endAt"
                    class="form-control"
                    :config="{
                      altInput: true,
                      altFormat: 'F j, Y H:i',
                      dateFormat: 'Y-m-dTH:i:S+00:00', // Date en UTC
                      enableTime: true,
                      time_24hr: true,
                      defaultDate: data.item.endAt,
                      locale: $i18n.locale,
                      // Permet d'avoir la date affichée en UTC
                      onReady: (selectedDates, dateStr, instance) => {
                        instance.setDate(new Date(selectedDates[0].toISOString()), false);
                      },
                    }"
                    @on-close="updateLineDate(data.item)"
                  />
                </b-form-group>
                <span v-else class="text-nowrap">
                  <span v-if="data.item.date">
                    {{ $moment.utc(data.item.endAt).format('ddd, MMM Do YYYY HH:mm') }}
                  </span>
                </span>
              </div>
            </template>
            <!-- status -->
            <template #cell(status)="data">
              <!-- Droit d'édition en tant que vendeur ou opérateur -->
              <b-form-group v-if="servicesEditionStatus && (selectedOrganization.operator || isQuoteOwner)" class="mt-md-1 input-group">
                <enum-select
                  :id="`order-view-service-${data.item.id}-status`"
                  v-model="data.item.status"
                  :placeholder="$t('common.status')"
                  :clearable="false"
                  :searchable="false"
                  enum-class="quoteLineStatus"
                  required
                  @input="updateLineStatus(data.item)"
                >
                  <template #option="{ value }">
                    <b-badge pill :variant="`${resolveSupplyLineStatusVariant(value)}`">
                      {{ value|enumTranslate('quote_line_status') }}
                    </b-badge>
                  </template>
                  <template #selected-option="{ value }">
                    <b-badge pill :variant="`${resolveSupplyLineStatusVariant(value)}`">
                      {{ value|enumTranslate('quote_line_status') }}
                    </b-badge>
                  </template>
                </enum-select>
              </b-form-group>
              <!-- Pas le droit d'édition ou client -->
              <b-badge v-else-if="!servicesEditionStatus || (!isQuoteOwner && !selectedOrganization.operator)" pill :variant="`${resolveSupplyLineStatusVariant(data.item.status)}`">
                {{ data.item.status|enumTranslate('quote_line_status') }}
              </b-badge>
            </template>
          </b-table>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import UIHelper from '@/helpers/ui'

import {
  patchQuoteQuoteLinesDatesRequest,
  patchQuoteQuoteLinesStatusRequest,
} from '@/request/globalApi/requests/quoteRequests'

import {mapActions, mapState} from 'vuex'

import {mapFields} from 'vuex-map-fields'

export default {
  name: 'OrderViewServices',

  mixins: [UIHelper],

  props: {
    servicesEditionDates: {
      type: Boolean,
      default: true,
    },
    servicesEditionStatus: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      quote: { lines: [] },
      allServicesAreNotDelivered: false,
    }
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'isQuoteOwner', 'quoteLoading']),
    ...mapState('auth', ['selectedOrganization']),
    ...mapFields('quote', ['quoteLoading']),

    linesDocumentFields() {
      return [
        {
          key: 'name',
          label: this.$t('common.name'),
        },
        {
          key: 'date',
          label: `${this.$t('common.date')} ${this.$t('common.utc')}`,
        },
        {
          key: 'status',
          label: this.$t('common.status'),
          thStyle: 'width: 18em',
          tdStyle: 'width: 18em',
        },
      ]
    },
  },

  watch: {
    'quoteSelected.quote': {
      handler(value) {
        this.quote = this._cloneDeep(value)
      },
      deep: true,
    },
    'quote.lines': {
      handler(value) {
        this.allServicesAreNotDelivered = !value.some(line => line.status !== 'delivered')
      },
      deep: true,
    },
  },

  mounted() {
    this.quote = this._cloneDeep(this.quoteSelected.quote)
  },

  methods: {
    ...mapActions('quote', ['fetchQuote']),

    canBeModifiedDeliveryDate(data) {
      return data.item.status !== 'in_progress' && data.item.status !== 'delivered'
    },

    updateLineDate(line) {
      if (line.date) {
        this.quoteLoading = true
        patchQuoteQuoteLinesDatesRequest(
          this.quoteSelected.quote.id,
          { quoteLinesDates: [{ quoteLineId: line.id, date: line.date, endAt: line.endAt }] },
          this.$store.getters['quote/quoteTitleAndRef'],
        )
          .then(() => {
            this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
              this.quoteLoading = false
            })
          })
          .catch(err => {
            err && (this.quoteLoading = false)
          })
      }
    },

    updateLineStatus(line) {
      this.quoteLoading = true
      patchQuoteQuoteLinesStatusRequest(
        this.quoteSelected.quote.id,
        { quoteLinesStatus: [{ quoteLineId: line.id, status: line.status }] },
        this.$store.getters['quote/quoteTitleAndRef'],
      )
        .then(() => {
          this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
            this.quoteLoading = false
          })
        })
        .catch(err => {
          err && (this.quoteLoading = false)
        })
    },

    updateAllServicesToDelivered() {
      this.quoteLoading = true
      const quoteLinesStatus = []
      this.quote.lines.forEach(line => {
        quoteLinesStatus.push({ quoteLineId: line.id, status: 'delivered' })
      })
      patchQuoteQuoteLinesStatusRequest(this.quoteSelected.quote.id, { quoteLinesStatus }, this.$store.getters['quote/quoteTitleAndRef'])
        .then(() => {
          this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
            this.quoteLoading = false
          })
        })
        .catch(err => {
          err && (this.quoteLoading = false)
        })
    },
    isValidLineForConditions(line) {
      return line.lineClass !== 'FuelQuoteLine' || (line.lineClass === 'FuelQuoteLine' && line.fuelSubPriceType === 'main');
    },
  },
}
</script>

<style lang="scss" scoped>
.table-dropdown-action {
  min-height: 250px;
}
.input-group {
  min-width: 180px;
}
.note-min-width {
  min-width: 300px;
}
</style>
